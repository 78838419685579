<template>
  <BaseModal
    dataClass="definition-item-modify-modal"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
    @show-modal="clear(obj)"
  >
    <template v-slot:main>
      <div data-class="work-list" class="mx-2 my-2">
        <BaseSearchCondition
          :searchCondition="searchCondition"
          :searchConditionInfo="searchConditionInfo"
          @clear="clear(obj)"
          @search="search(obj)"
        >
        </BaseSearchCondition>
        <br />
        <!-- テーブル -->
        <BasePagingTable
          v-model="searchCondition.size"
          id="work-list-table"
          select-mode="range"
          :fields="fields"
          :items.sync="tableItems"
          :selected-items.sync="selectedItem"
          :columnInfoPage="pagingInfo.page"
          :columnInfoSize="pagingInfo.size"
          :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
          :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
          :perPage="perPage"
          @size-changed="updateSize"
        />
      </div>
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- 選択ボタン -->
      <BaseButton
        variant="primary"
        icon="check-circle"
        :columnInfo="resultControlInfo.select"
        :disabled="!multiSelected"
        @click="select"
      />
      <!-- 選択解除ボタン -->
      <BaseButton
        v-if="useUnset"
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.unselect"
        @click="unselect"
      />
      <!-- 閉じるボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.close"
        @click="cancel"
      />
    </template>
  </BaseModal>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
} from '@/common/Common.js'
import { search, clear } from '@/master/productPattern/ProductPatternModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PRODUCT_PATTERN_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 荷姿パターンキー情報(機種)
            type: 'text',
            id: 'productPatternKey',
            columnInfo: searchConditionInfo.product_pattern_key,
            maxLength: 50,
            rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
          },
          {
            // 荷姿パターン名
            type: 'text',
            id: 'productPatternName',
            columnInfo: searchConditionInfo.product_pattern_name,
            maxLength: 50,
          },
          {
            // 荷姿パターン名サブ
            type: 'text',
            id: 'productPatternNameSub',
            columnInfo: searchConditionInfo.product_pattern_name_sub,
            maxLength: 50,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PRODUCT_PATTERN_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PRODUCT_PATTERN_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
    /**
     * 複数選択しているか.
     * @return {Boolean}
     */
    multiSelected() {
      return this.selectedItem.length >= 1
    },
  },

  methods: {
    getListOptions,
    search,
    clear,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        productPatternId: null,
        productPatternKey: null,
        productPatternName: null,
        productPatternNameSub: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.PRODUCT_PATTERN_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      this.$bvModal.hide('product-pattern-modal')
      this.$emit('after-close-set', this.selectedItem)
    },

    unselect() {
      this.$bvModal.hide('product-pattern-modal')
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },
  },
}
</script>

<style scoped></style>
